import { Action, action } from 'easy-peasy'

export interface UIModel {
  invader: string
  setInvader: Action<UIModel, string>
}

export const uiModel: UIModel = {
  invader: '',
  setInvader: action((state, invader) => {
    state.invader = state.invader === invader ? '' : invader
  }),
}
