import { ApolloProvider } from '@apollo/react-hooks'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import translations from 'data/translations.json'
import { createStore, StoreProvider } from 'easy-peasy'
import { MediaContextProvider } from 'media'
import { storeModel } from 'models'
import { DefaultSeo } from 'next-seo'
import NextApp from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import 'styles/app.scss'
import { theme } from 'theme'
import { withApollo } from 'utils/withApollo'

// @ts-ignore
if (global.Headers == null) {
  const fetch = require('node-fetch')
  // @ts-ignore
  global.Headers = fetch.Headers
}

class App extends NextApp<{ apollo: ApolloClient<NormalizedCacheObject> }> {
  private store = createStore(storeModel)

  componentDidMount() {
    Router.events.on('routeChangeStart', () => {
      this.store.getActions().ui.setInvader('')
    })
  }

  render() {
    const { Component, pageProps, apollo } = this.props

    return (
      <StoreProvider store={this.store}>
        <IntlProvider locale="es-MX" messages={translations}>
          <ApolloProvider client={apollo}>
            <MediaContextProvider>
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
              </Head>
              <DefaultSeo
                openGraph={{
                  site_name: 'Yucatan Premier',
                  type: 'website',
                }}
                twitter={{
                  cardType: 'summary_large_image',
                  site: '@yucatanpremier',
                }}
              />
              <ThemeProvider theme={theme}>
                <Component {...pageProps} />
              </ThemeProvider>
            </MediaContextProvider>
          </ApolloProvider>
        </IntlProvider>
      </StoreProvider>
    )
  }
}

export default withApollo(App)
