import { getDataFromTree } from '@apollo/react-ssr'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, fromPromise, Operation } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { RestLink } from 'apollo-link-rest'
import axios from 'axios'
import nextWithApollo from 'next-with-apollo'
import { parseCookies, setCookie } from 'nookies'

const API = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1/`,
})

export const withApollo = nextWithApollo(
  ({ ctx, initialState }) => {
    let { token } = parseCookies(ctx)

    const authLink = setContext(async (operation, { headers }) => {
      const resolve = () => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      })

      if (token) {
        return resolve()
      }

      const { data } = await API.post('sessions')

      setCookie(ctx, 'token', data.jwt, {
        maxAge: 2147483647,
        path: '/',
      })

      token = data.jwt

      return resolve()
    })

    const refreshToken = async (operation: Operation) => {
      const { headers } = operation.getContext()
      let newToken

      try {
        const { data } = await API.request({
          url: 'sessions/refresh',
          method: 'POST',
          headers: { authorization: headers.authorization },
        })

        newToken = data.jwt
      } catch {
        const { data } = await API.post('sessions')
        newToken = data.jwt
      }

      setCookie(ctx, 'token', newToken, {
        maxAge: 2147483647,
        path: '/',
      })

      token = newToken
    }

    let isRefreshing = false
    let pendingRequests: Function[] = []

    const errorLink = onError(({ networkError, operation, forward }) => {
      // @ts-ignore
      if (networkError?.statusCode === 401) {
        let forward$

        if (!isRefreshing) {
          isRefreshing = true

          forward$ = fromPromise(
            refreshToken(operation)
              .then(() => {
                pendingRequests.forEach((fn) => fn())
                pendingRequests = []
                return true
              })
              .catch((error) => {
                console.log('[ERROR] Token Refresh', error)
                pendingRequests = []
              })
              .finally(() => {
                isRefreshing = false
              }),
          ).filter(Boolean)
        } else {
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve())
            }),
          )
        }

        return forward$.flatMap(() => forward(operation))
      }
    })

    const restLink = new RestLink({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/v1/`,
      endpoints: { content: `${process.env.NEXT_PUBLIC_CONTENT_API_URL}/ghost/api/v2/content/` },
    })

    const link = ApolloLink.from([errorLink, authLink, restLink])

    const cache = new InMemoryCache().restore(initialState || {})

    return new ApolloClient({ link, cache })
  },
  {
    getDataFromTree,
  },
)
